




















import { Product } from "@/Types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ListItemViewer extends Vue {
  @Prop() listitem!: Product;
  total: number = 0;
  mounted() {
    console.log(this.listitem.title, "has been mounted");
    const str: string | null = localStorage.getItem(this.listitem.id);
    if (str) {
      this.total = Number.parseInt(str, 10);
    }
  }
  addToCart() {
    this.total++;
    console.log("added to cart", this.listitem);
    this.$emit("addedToCart", this.listitem);
    this.saveCount();
  }
  removeFromCart() {
    this.total--;
    this.$emit("removedFromCart", this.listitem);
    this.saveCount();
  }
  saveCount() {
    localStorage.setItem(this.listitem.id, "" + this.total);
  }
  get price(): string {
    const price: number = this.listitem.price;
    let ans: string = "$";
    const dollarAmount: number = Math.floor((price * 100) / 100);
    const cents: number = Math.round((price * 100) % 100);
    ans += dollarAmount + "." + cents;
    return ans;
  }
}
