


















































import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "../components/HelloWorld.vue";
import mylistitem from "../components/mylistitem.vue";
import { Product } from "../Types";
@Component({
  components: {
    HelloWorld,
    mylistitem,
  },
})
export default class BuyStuff extends Vue {
  message: string = "Hello World aka cs 285S";
  cart: Product[] = [];
  mounted() {
    // console.log("feijfeifjfeifeijfeifj MOUNTED!!!!!!");
    const str: string | null = localStorage.getItem("cart");
    if (str) {
      this.cart = JSON.parse(str);
    }
  }
  get itemTitles(): string[] {
    return this.cart.map((x) => x.title);
  }
  get totalPrice(): string {
    const price = this.cart.reduce((acc, x) => x.price + acc, 0);
    let ans: string = "$";
    const dollarAmount: number = Math.floor((price * 100) / 100);
    const cents: number = Math.round((price * 100) % 100);
    ans += dollarAmount + "." + cents;
    return ans;
  }
  addToCart(p: Product) {
    this.cart.push(p);
    localStorage.setItem("cart", JSON.stringify(this.cart));
  }
  removedFromCart(p: Product) {
    console.log("removedFromCart", p);
    const e = this.cart.find((x) => x.id == p.id);
    if (e) {
      const i = this.cart.indexOf(e);
      if (i >= 0) {
        this.cart.splice(i, 1);
      }
    }
  }
  items: Product[] = [
    {
      title: "Paper",
      price: 1.1,
      id: "paper",
      description: "Literally a sheet of paper.",
    },
    {
      title: "Pen",
      price: 3.56,
      id: "pen",
      description: "Literally a pen.",
    },
    {
      title: "Desk",
      price: 75.99,
      id: "desk",
      description: "A right handed student desk.",
    },
  ];
  foo(): any {
    this.message;
  }
}
